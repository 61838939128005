import { api } from "../config";
import tokenExtraction from "../decode";

const userId = tokenExtraction(sessionStorage.getItem("authUser"))?.id;

// user apis
export const LOGIN_USER = api.API_URL + "/login";
export const REGISTER_USER = api.API_URL + "/register";
export const GET_USER = api.API_URL + "/getUser";
export const GET_ADDRESS = api.API_URL + "/getAddress";
export const NEW_ADDRESS = api.API_URL + "/newAddress";
export const UPDATE_ADDRESS = api.API_URL + "/updateAddress";
export const DELETE_ADDRESS = api.API_URL + "/deleteAddress";
export const UPDATE_USER = api.API_URL + "/updateUser";
//  apis
export const GET_RECENT_SEARCHES = api.API_URL + "/GetRecentSearches";
export const GET_PRODUCT_SEARCH = api.API_URL + "/GlobalSearch";

// product page
export const GET_ALL_PRODUCTS = api.API_URL + "/ProductPage";

// product listing page
export const GET_ALL_PRODUCTLISTING = api.API_URL + "/ProductList";
export const GET_ALL_PRODUCTLISTING_CATEGORY = api.API_URL + "/CategoryList";
export const GET_ALL_PRODUCTLISTING_BRAND = api.API_URL + "/BrandList";
export const GET_ALL_PRODUCTLISTING_PARTS = api.API_URL + "/PartList";
export const GET_ALL_PRODUCTLISTING_FILTER = api.API_URL + "/ProductFilter";

// product details page
export const GET_PRODUCT_DETAILS = api.API_URL + "/ProductDetail";

// product wishlisting page
export const GET_WISHLIST = api.API_URL + "/getWishlist";
export const POST_WISHLIST = api.API_URL + "/postWishlist";
export const GET_TOTAL_WISHLIST = api.API_URL + "/getTotalProductsInWishlist";
export const IS_PRODUCT_IN_WISHLIST = api.API_URL + "/isProductInWishlist";
export const REMOVE_PRODUCT_FROM_WISHLIST = api.API_URL + "/removeProductFromWishlist";
export const CLEAR_WISHLIST = api.API_URL + "/clearWishlist";

// cart apis
export const GET_CART = api.API_URL + "/getCart";
export const ADD_To_CART = api.API_URL + "/addToCart";
export const UPDATE_QUANTITY = api.API_URL + "/updateQuantity";
export const REMOVE_ITEM = api.API_URL + "/removeItem";

// payment apis
export const CREATE_CHECKOUT_SESSION = api.API_URL + "/CreateCheckoutSession";

export const GET_SINGLE_INVOICE = api.API_URL + "/feInvoice/view";
export const UPDATE_SINGLE_INVOICE = api.API_URL + "/feInvoice/updated";
export const FETCH_INVOICE_LOGS = api.API_URL + "/feInvoice/invoiceParents";

// Contact api
export const GET_CONTACT = api.API_URL + "/getContact";

//Policy & Privacy api
export  const GET_PRIVACY_POLICY = api.API_URL + "/getPrivacy";

//Terms & Condition api
export const GET_TERMS_CONDITION = api.API_URL + "/getTandC";

//Get Order api
export const GET_ORDER = api.API_URL + "/getOrder";

//get status api
export const GET_STATE = api.API_URL + "/stateList";

//get Locator api
export const GET_LOCATOR = api.API_URL + "/locator";

//get Ratingapi
export const GET_RATING = api.API_URL + "/rating";