import { getOrderApiResponseSuccess, getOrderApiResponseError, getOrder } from "./action";
import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { GET_ORDER } from "./actionType";
import { get_order } from "../../helpers/backend_helper";

function* getOrderProducts({payload:data}){
    try{
      const response = yield call(get_order, data);
      console.log('GET_ORDER  Saga:', response.data)
      if(response.status === 200){
        yield put(getOrderApiResponseSuccess(GET_ORDER, response.data))
      }else {
        yield put(getOrderApiResponseError(GET_ORDER, response));
      }
    }catch (error) {
      yield put(getOrderApiResponseError(GET_ORDER, error));
    }
}

export function* watchGetOrders(){
    yield takeEvery(GET_ORDER, getOrderProducts);
  }

  function* OrderProductSaga() {
    yield all([
      fork(watchGetOrders),
    ]);
  }
  
  
  
  export default OrderProductSaga;