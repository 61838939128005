import { jwtDecode } from 'jwt-decode';
import { setAuthorization } from './helpers/api_helper';

const tokenExtraction = (token) => {
    let decodedToken = null;
    let curTime = parseInt((new Date().getTime() + 1) / 1000);
    if (token) {
        decodedToken = jwtDecode(token);
        if (decodedToken.exp < curTime) {
            sessionStorage.removeItem("authUser");
            return decodedToken = null;
        }
    }
    setAuthorization(token);
    return decodedToken;
}

export default tokenExtraction;