// import { act } from "react-dom/test-utils";
import { API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, GET_PRODUCTS, SET_PRODUCT_FILTER } from "./actionType";

const INIT_STATE = {
    categories: [],
    brands: [],
    parts: [],
    filter:[],
    error: {},
    loading: false,
    errorStatus: false
};

const Product = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_PRODUCTS:
                    return {
                        ...state,
                        categories: action.payload.data.shopbycategory,
                        brands: action.payload.data.shopbybrand,
                        parts: action.payload.data.shopbyparts,
                        loading: false,
                        errorStatus: false
                    };
                default:
                    return { ...state };
            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_PRODUCTS:
                    return {
                        ...state,
                        error: action.payload.error,
                        errorStatus: true,
                        loading: false
                    };
                default:
                return { ...state };
            }
        
        case GET_PRODUCTS:
            return {
                ...state,
                loading: true,
                errorStatus: false
            };
     
        default:
            return { ...state };
    }
}

export default Product;