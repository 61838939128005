// import { act } from "react-dom/test-utils";
import { API_PRODUCT_DETAILS_ERROR, API_PRODUCT_DETAILS_SUCCESS, GET_PRODUCT_DETAILS } from './actionType.js';

const INIT_STATE = {
    product: {},
    relatedProducts: [],
    filter:[],
    error: {},
    loading: false,
    errorStatus: false
};

const ProductDetails = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_PRODUCT_DETAILS_SUCCESS:
            switch (action.payload.actionType) {
                case GET_PRODUCT_DETAILS:
                    return {
                        ...state,
                        product: action.payload.data.data,
                        relatedProducts: action.payload.data.related,
                        loading: false,
                        errorStatus: false
                    }
                
                default:
                    return { ...state };
            }

        case API_PRODUCT_DETAILS_ERROR:
            switch (action.payload.actionType) {
                case GET_PRODUCT_DETAILS:
                    return {
                        ...state,
                        error: action.payload.data.message,
                        errorStatus: true,
                        loading: false
                    }
                
                default:
                return { ...state };
            }
        
        default:
            return { ...state };
    }
}

export default ProductDetails;