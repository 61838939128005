import axios from "axios";

// default
// axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.common["Accept"] = "*/*"
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common["Authorization"] = "Bearer Bearer";
// content type
const token = sessionStorage.getItem("authUser") ? sessionStorage.getItem("authUser") : null;
if (token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  // intercepting to capture errors
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      let message;
      switch (error.response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        case 400:
          message = error.response.data.message;
          break;
        default:
          message = error.message || error;
      }
      return Promise.reject(message);
    }
  );
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).forEach(key => {
        if (Array.isArray(params[key])) {
          params[key].forEach(value => {
            paramKeys.push(`${key}[]=${encodeURIComponent(value)}`);
          });
        } else {
          paramKeys.push(`${key}=${encodeURIComponent(params[key])}`);
        }
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
  /**
   * post
   */
  post = (url, data) => {
    return axios.post(url, data);
  }

}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };