import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import { authenticationResponseSuccess, authenticationResponseError, getUser } from "./action";
import { clear_wishlist, get_user, get_wishlist, login_user, post_wishlist, register_user, get_address, new_address, update_address, delete_address } from "../../helpers/backend_helper";
import { DELETE_WISHLIST, GET_USER, GET_WISHLIST, LOGIN_USER, POST_WISHLIST, REGISTER_USER, GET_ADDRESS, NEW_ADDRESS, UPDATE_ADDRESS, DELETE_ADDRESS, LOGOUT_USER } from "./actionType";
import Swal from "sweetalert2";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import tokenExtraction from "../../decode";
// import jwt from "jsonwebtoken"

function* postRegisterUser({ payload: data }) {
  try {
    const response = yield call(register_user, data);
    if (response.status === 200) {
      // Swal.fire('Registered', response.data.message, 'success');
      toast.success('Registered', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      yield put(authenticationResponseSuccess(REGISTER_USER, response.data));
      document.querySelector('.modal-backdrop')?.classList?.add('d-none');
      document.querySelector('#registerModal')?.classList?.add('d-none');
      document.querySelector('.petrosolutions')?.classList?.add('pe-0');
    } else {
      // Swal.fire('Fail to Register', response.data.message, 'error');
      toast.error('Fail to Register', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      yield put(authenticationResponseError(REGISTER_USER, response.data));
    }
  } catch (error) {
    // Swal.fire('Fail to Register', error, 'error');
    toast.error('Fail to Register', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    yield put(authenticationResponseError(REGISTER_USER, error));
  }
}

function* getUsers({ payload: data }) {
  try {
    const response = yield call(get_user, data);
    if (response.status === 200) {
      yield put(authenticationResponseSuccess(GET_USER, response.data));
    } else {
      yield put(authenticationResponseError(GET_USER, response.data));
    }
  } catch (error) {
    yield put(authenticationResponseError(GET_USER, error));
  }
}

function* postLoginUser({ payload: data }) {
  try {
    const response = yield call(login_user, data);
    if (response.status === 200) {

      sessionStorage.setItem('authUser', response.data.token);
      toast.success('Logged In', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      // Swal.fire('Logged In', response.data.message, 'success');
      yield put(authenticationResponseSuccess(LOGIN_USER, response.data));

      document.querySelector('.petrosolutions')?.classList?.add('pe-0');
      document.querySelector('.modal-backdrop')?.classList?.add('d-none');
      document.querySelector('#exampleModal')?.classList?.add('d-none');
    } else {
      // Swal.fire('Fail to Login', response.data.message, 'error');
      toast.error('Fail to Login', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      yield put(authenticationResponseError(LOGIN_USER, response.data));
    }
  } catch (error) {
    // Swal.fire('Fail to Login', error, 'error');
    toast.error('Fail to Login', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    yield put(authenticationResponseError(LOGIN_USER, error));
  }
}

function* postWishlist({ payload: data }) {
  try {
    const response = yield call(post_wishlist, data);
    if (response.status === 200) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      yield put(authenticationResponseSuccess(POST_WISHLIST, response.data));
    } else {
      toast.error(' Fail to add to wishlist', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      yield put(authenticationResponseError(POST_WISHLIST, response.data));
    }
  } catch (error) {
    // Swal.fire('Fail to add to wishlist', error, 'error');
    toast.error('Fail to add to wishlist', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    yield put(authenticationResponseError(POST_WISHLIST, error));
  }
}

function* getWishlist({ payload: data }) {
  try {
    const response = yield call(get_wishlist, data);
    if (response.status === 200) {
      yield put(authenticationResponseSuccess(GET_WISHLIST, response.data));
    } else {
      yield put(authenticationResponseError(GET_WISHLIST, response.data));
    }
  } catch (error) {
    yield put(authenticationResponseError(GET_WISHLIST, error));
  }
}

function* deleteWishlist({ payload: data }) {
  try {
    const response = yield call(clear_wishlist, data);
    if (response.status === 200) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      yield put(authenticationResponseSuccess(GET_WISHLIST, response.data));
    } else {
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      yield put(authenticationResponseError(GET_WISHLIST, response.data));
    }
  } catch (error) {
    Swal.fire('Fail to delete wishlist', error, 'error');
    yield put(authenticationResponseError(GET_WISHLIST, error));
  }
}

function* getAddress({ payload: data }) {
  try {
    const response = yield call(get_address, data);
    if (response.status === 200) {
      yield put(getUser({ id : data.id }));
    } else {
      yield put(authenticationResponseError(GET_ADDRESS, response.data));
    }
  } catch (error) {
    yield put(authenticationResponseError(GET_ADDRESS, error));
  }
}

function* newAddress({ payload: data }) {
  try {
    const response = yield call(new_address, data);
    if (response.status === 200) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      yield put(getUser({ id : data.userId }));
    } else {
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      yield put(authenticationResponseError(NEW_ADDRESS, response.data));
    }
  } catch (error) {
    toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    yield put(authenticationResponseError(NEW_ADDRESS, error));
  }
}

function* updateAddress({ payload: data }) {
  try {
    const response = yield call(update_address, data);
    console.log(response);
    if (response.status === 200) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      yield put(getUser({ id : tokenExtraction(sessionStorage.getItem('authUser'))?.id }));
      // yield put(getUser({ userId : data.userId }));
    } else {
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light", 
      });
      yield put(authenticationResponseError(UPDATE_ADDRESS, response.data));
    }
  } catch (error) {
    toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    yield put(authenticationResponseError(UPDATE_ADDRESS, error));
  }
}

function* deleteAddress({ payload: data }) {
  try {
    const response = yield call(delete_address, data);
    if (response.status === 200) {
      toast.success(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
      yield put(getUser({ id : tokenExtraction(sessionStorage.getItem('authUser'))?.id }));
    } else {
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      yield put(authenticationResponseError(DELETE_ADDRESS, response.data));
    }
  } catch (error) {
    toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    yield put(authenticationResponseError(DELETE_ADDRESS, error));
  }
}

function* logoutUser() {
  try {
    document.querySelector('.petrosolutions')?.classList?.remove('pe-0');
    document.querySelector('.modal-backdrop')?.classList?.remove('d-none');
    document.querySelector('#exampleModal')?.classList?.remove('d-none');
    yield put(authenticationResponseSuccess(LOGOUT_USER, null));
  } catch (error) {
    yield put(authenticationResponseError(LOGOUT_USER, error));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, postRegisterUser);
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, postLoginUser);
}

export function* watchGetUser() {
  yield takeEvery(GET_USER, getUsers);
}

export function* watchPostWishlist() {
  yield takeEvery(POST_WISHLIST, postWishlist);
}

export function* watchGetWishlist() {
  yield takeEvery(GET_WISHLIST, getWishlist);
}

export function* watchDeleteWishlist() {
  yield takeEvery(DELETE_WISHLIST, deleteWishlist);
}

export function* watchGetAddress() {
  yield takeEvery(GET_ADDRESS, getAddress);
}

export function* watchNewAddress() {
  yield takeEvery(NEW_ADDRESS, newAddress);
}

export function* watchUpdateAddress() {
  yield takeEvery(UPDATE_ADDRESS, updateAddress);
}

export function* watchDeleteAddress() {
  yield takeEvery(DELETE_ADDRESS, deleteAddress);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default function* userSaga() {
  yield all([
    fork(watchRegisterUser),
    fork(watchLoginUser),
    fork(watchGetUser),
    fork(watchPostWishlist),
    fork(watchGetWishlist),
    fork(watchDeleteWishlist),
    fork(watchGetAddress),
    fork(watchNewAddress),
    fork(watchUpdateAddress),
    fork(watchDeleteAddress),
    fork(watchLogoutUser),
  ]);
}