import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { searchApiResponseSeccess, searchApiResponseError } from "./action";
import { GET_RECENT_SEARCHES, GET_SEARCH_PRODUCT } from "./actionType";
import { get_recent_search, get_searched_product } from "../../helpers/backend_helper";

function* getRecentSearch({ payload: data }) {
    try {
        const response = yield call(get_recent_search, data);
        if(response.status === 200){
        yield put(searchApiResponseSeccess(GET_RECENT_SEARCHES, response.data));
        }else{
        yield put(searchApiResponseError(GET_RECENT_SEARCHES, response));
        }
    } catch (error) {
        yield put(searchApiResponseError(GET_RECENT_SEARCHES, error));
    }
}

function* getSearcheddProduct({ payload: data }) {
    try {
        const response = yield call(get_searched_product, data);
        if(response.status === 200){
        yield put(searchApiResponseSeccess(GET_SEARCH_PRODUCT, response.data));
        }else{
        yield put(searchApiResponseError(GET_SEARCH_PRODUCT, response));
        }
    } catch (error) {
        yield put(searchApiResponseError(GET_SEARCH_PRODUCT, error));
    }
}



export function* watchGetRecentSearch() {
    yield takeEvery(GET_RECENT_SEARCHES, getRecentSearch);
}

export function* watchGetSearchedProduct() {
    yield takeEvery(GET_SEARCH_PRODUCT, getSearcheddProduct);
}

function* searchSaga() {
    yield all([
        fork(watchGetRecentSearch),
        fork(watchGetSearchedProduct)
    ]);
}

export default searchSaga;