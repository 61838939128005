import "./App.css";
import React, { useState, useEffect, Suspense } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import tokenExtraction from "./decode";
import AuthenticatedRoute from "./AuthenticatedRoute";
import "./assets/css/style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/icomoon/icomoon.css";

const Header = React.lazy(() => import("./components/layouts/header"));
const Home = React.lazy(() => import("./pages/home/home"));
const Login = React.lazy(() => import("./pages/auth/login"));
const Register = React.lazy(() => import("./pages/auth/register"));
const Product = React.lazy(() => import("./pages/product/product"));
const ProductListing = React.lazy(() => import("./pages/productListing/productListing"));
const ProductDetail = React.lazy(() => import("./pages/productDetail/productDetail"));
const Services = React.lazy(() => import("./pages/Services/services"));
const SuccessModule = React.lazy(() => import("./pages/checkout/components/success"));
const FailModule = React.lazy(() => import("./pages/checkout/components/failModule.jsx"));
const AboutUs = React.lazy(() => import("./pages/aboutUs/aboutUs"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacyPolicy.jsx/privacyPolicy"));
const TermsAndCondition = React.lazy(() => import("./pages/termsCondition/termsAndCondition"));
const Checkout = React.lazy(() => import("./pages/checkout/checkout"));
const ContactUs = React.lazy(() => import("./pages/contactUs/contactUs"));
const Dashboard = React.lazy(() => import("./pages/auth/dashboard/dashboard"));
const Loader = React.lazy(() => import("./components/others/Loader"));

// import Header from "./components/layouts/header";
// import Home from "./pages/home/home";
// import Login from "./pages/auth/login";
// import Register from "./pages/auth/register";
// import Product from "./pages/product/product";
// import ProductListing from "./pages/productListing/productListing";
// import ProductDetail from "./pages/productDetail/productDetail";
// import Services from "./pages/Services/services";
// import SuccessModule from "./pages/checkout/components/success";
// import FailModule from "./pages/checkout/components/failModule.jsx";
// import AboutUs from "./pages/aboutUs/aboutUs";
// import PrivacyPolicy from "./pages/privacyPolicy.jsx/privacyPolicy";
// import TermsAndCondition from "./pages/termsCondition/termsAndCondition";
// import 'react-toastify/dist/ReactToastify.css';
// import Checkout from "./pages/checkout/checkout";
// import ContactUs from "./pages/contactUs/contactUs";
// import Dashboard from "./pages/auth/dashboard/dashboard";
// import Loader from "./components/others/Loader";


const router = [
  {
    path: "/",
    element: <Home />,
    auth: false
  },
  {
    path: "/login",
    element: <Login />,
    auth: false
  },
  {
    path: "/register",
    element: <Register />,
    auth: false
  },
  {
    path: "/about-us",
    element: <AboutUs />,
    auth: false
  },
  {
    path: "/services",
    element: <Services />,
    auth: false
  },
  {
    path: "/product",
    element: <Product />,
    auth: false
  },
  {
    path: "/product-listing",
    element: <ProductListing />,
    auth: false
  },
  {
    path: "/product-detail/:productId",
    element: <ProductDetail />,
    auth: false
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
    auth: false
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    auth: false
  },
  {
    path: "/terms-conditions",
    element: <TermsAndCondition />,
    auth: false
  },
  {
    path: "/checkout",
    element: <Checkout />,
    auth: false
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    auth: true
  }
  ,
  {
    path: "/success-payment",
    element: <SuccessModule />,
  }
  ,
  {
    path: "/fail-payment",
    element: <FailModule />,
  }


];

function App() {
  const [loading, setLoading] = useState(true);
  const todos = useSelector(state => state.todos);
  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data from an API)
    const fetchData = async () => {
      try {
        // Simulate a delay (you can replace this with an actual asynchronous operation)
        await new Promise((resolve) => setTimeout(resolve, 2000));

        // Once the data is fetched or the asynchronous operation is completed, set loading to false
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []);
  return (
    <>
      {loading ? ( // Display a loading spinner or any other loading indicator
        <div className="d-flex justify-content-center  align-items-center" style={{ height: '100vh' }}>
          <Loader />
        </div>
      ) : (
        <React.StrictMode>
          <BrowserRouter>
            <Suspense fallback={<div className="d-flex justify-content-center  align-items-center" style={{ height: '100vh' }}><Loader /></div>}>
              <ToastContainer />
              <Routes>
                {router.map(({ path, element, auth }, index) => (
                  <Route
                    key={index}
                    path={path}
                    element={
                      auth ? (
                        <AuthenticatedRoute>
                          {element}
                        </AuthenticatedRoute>
                      ) : (
                        element
                      )
                    }
                  />
                ))}
              </Routes>
            </Suspense>
          </BrowserRouter>
        </React.StrictMode>
      )}
    </>
  );
}

export default App;
