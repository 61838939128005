// import { act } from "react-dom/test-utils";
import { ADD_TO_CART, CART_API_RESPONSE_FAIL, CART_API_RESPONSE_SUCCESS, GET_CART, REMOVE_ITEM, UPDATE_QUANTITY } from "./actionType";

const INIT_STATE = {
    cart: [],
    error: "",
    cartLoading: false,
    errorStatus: false
};

const Cart = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CART_API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_CART:
                    return {
                        ...state,
                        cart: action.payload.data.data,
                        cartLoading: false,
                        errorStatus: false
                    };
                
                case ADD_TO_CART:
                    return {
                        ...state,
                        cart: action.payload.data.data,
                        cartLoading: false,
                        errorStatus: false
                    };
                
                case UPDATE_QUANTITY:
                    return {
                        ...state,
                        cart: action.payload.data.data,
                        cartLoading: false,
                        errorStatus: false
                    };
                
                case REMOVE_ITEM:
                    return {
                        ...state,
                        cart: action.payload.data.data,
                        cartLoading: false,
                        errorStatus: false
                    };
                
                default:
                    return { ...state };
            }
        case CART_API_RESPONSE_FAIL:
            switch (action.payload.actionType) {
                case GET_CART:
                    return {
                        ...state,
                        errorStatus: true,
                        cartLoading: false,
                        error: action.payload.data ? action.payload.data.message : action.payload.error
                    };
                
                case ADD_TO_CART:
                    return {
                        ...state,
                        errorStatus: true,
                        cartLoading: false,
                        error: action.payload.data ? action.payload.data.message : action.payload.error
                    };
                
                case UPDATE_QUANTITY:
                    return {
                        ...state,
                        errorStatus: true,
                        cartLoading: false,
                        error: action.payload.data ? action.payload.data.message : action.payload.error
                    };
                
                case REMOVE_ITEM:
                    return {
                        ...state,
                        errorStatus: true,
                        cartLoading: false,
                        error: action.payload.data ? action.payload.data.message : action.payload.error
                    };
                
                default:
                return { ...state };
            }
        
        case GET_CART:
            return {
                ...state,
                cartLoading: true
            };
        
        default:
            return { ...state };
    }
}

export default Cart;