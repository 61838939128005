// import { act } from "react-dom/test-utils";
import { GET_RECENT_SEARCHES, GET_SEARCHES_ERROR, GET_SEARCHES_SUCCESS, GET_SEARCH_PRODUCT } from "./actionType";

const INIT_STATE = {
    recentSearches: [],
    searchedProducts: [],
    error: {},
    loading: false,
    errorStatus: false
};

const Search = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SEARCHES_SUCCESS:
            switch (action.payload.actionType) {
                case GET_RECENT_SEARCHES:
                    return {
                        ...state,
                        recentSearches: action.payload.data.data,
                        loading: false,
                        errorStatus: false
                    };
                case GET_SEARCH_PRODUCT:
                    return {
                        ...state,
                        searchedProducts: action.payload.data.data,
                        loading: false,
                        errorStatus: false
                    };
                default:
                    return { ...state };
            }
        case GET_SEARCHES_ERROR:
            switch (action.payload.actionType) {
                case GET_RECENT_SEARCHES:
                    return {
                        ...state,
                        error: action.payload.error,
                        errorStatus: true,
                        loading: false
                    };
                case GET_SEARCH_PRODUCT:
                    return {
                        ...state,
                        error: action.payload.error,
                        errorStatus: true,
                        loading: false
                    };
                default:
                return { ...state };
            }
        
        default:
            return { ...state };
    }
}

export default Search;