export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_USER = "LOGIN_USER";
export const GET_USER = "GET_USER";
export const POST_WISHLIST = "POST_WISHLIST";
export const GET_WISHLIST = "GET_WISHLIST";
export const DELETE_WISHLIST = "DELETE_WISHLIST";
export const GET_ADDRESS = "GET_ADDRESS";
export const NEW_ADDRESS = "NEW_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const LOGOUT_USER = "LOGOUT_USER";

