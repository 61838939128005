import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// user
export const login_user = data => api.post(url.LOGIN_USER, data);
export const register_user = data => api.post(url.REGISTER_USER, data);
export const get_user = data => api.post(url.GET_USER, data);
export const get_address = data => api.post(url.GET_ADDRESS, data);
export const new_address = data => api.post(url.NEW_ADDRESS, data);
export const update_address = data => api.post(url.UPDATE_ADDRESS, data);
export const delete_address = data => api.post(url.DELETE_ADDRESS, data);
export const update_user = data => api.post(url.UPDATE_USER, data);
//  apis
export const get_recent_search = data => api.get(url.GET_RECENT_SEARCHES, data)
export const get_searched_product = data => api.get(url.GET_PRODUCT_SEARCH, data)

// product page
export const get_products = data => api.get(url.GET_ALL_PRODUCTS, data)

// product listing page
export const get_product_list = data => api.get(url.GET_ALL_PRODUCTLISTING, data)
export const get_product_list_category = data => api.get(url.GET_ALL_PRODUCTLISTING_CATEGORY, data)
export const get_product_list_brand = data => api.get(url.GET_ALL_PRODUCTLISTING_BRAND, data)
export const get_product_list_parts = data => api.get(url.GET_ALL_PRODUCTLISTING_PARTS, data)
export const get_product_list_filter = data => api.get(url.GET_ALL_PRODUCTLISTING_FILTER, data)

// product details
export const get_product_details = data => api.post(url.GET_PRODUCT_DETAILS, data)

// product wishlisting page
export const get_wishlist = data => api.post(url.GET_WISHLIST, data)
export const post_wishlist = data => api.post(url.POST_WISHLIST, data)
export const get_total_wishlist = data => api.post(url.GET_TOTAL_WISHLIST, data)
export const is_product_in_wishlist = data => api.post(url.IS_PRODUCT_IN_WISHLIST, data)
export const remove_product_from_wishlist = data => api.post(url.REMOVE_PRODUCT_FROM_WISHLIST, data)
export const clear_wishlist = data => api.post(url.CLEAR_WISHLIST, data)

// cart apis
export const get_cart = data => api.post(url.GET_CART, data)
export const add_to_cart = data => api.post(url.ADD_To_CART, data)
export const update_quantity = data => api.post(url.UPDATE_QUANTITY, data)
export const remove_item = data => api.post(url.REMOVE_ITEM, data)

// payment apis
export const create_checkout_session = data => api.post(url.CREATE_CHECKOUT_SESSION, data);

export const get_invoices = data => api.create(url.GET_ALL_PRODUCTS, data)
export const upload_invoice = data => api.create(url.GET_ALL_PRODUCTS, data)
export const view_invoice = data => api.create(url.GET_SINGLE_INVOICE, data)
export const update_single_invoice = data => api.create(url.UPDATE_SINGLE_INVOICE, data)
export const fetch_all_invoice_logs = data => api.create(url.FETCH_INVOICE_LOGS, data)

// contact api
export const fetch_contact = data => api.get(url.GET_CONTACT, data)

// privacy Policy api
export const privacy_policy = data => api.get(url.GET_PRIVACY_POLICY, data)

//Terms & Condition api
export const terms_and_conditions = data => api.get(url.GET_TERMS_CONDITION, data)

// get Order api
export const get_order = data => api.post(url.GET_ORDER, data)

//get State api
export const get_state = data => api.get(url.GET_STATE, data)

//get Locator api
export const get_locator = data => api.post(url.GET_LOCATOR, data)
//get Rating api
export const get_rating = data => api.post(url.GET_RATING, data)