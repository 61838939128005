import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { productApiResponseError, productApiResponseSuccess } from "./action";
import { GET_PRODUCTS } from "./actionType";
import {  get_products } from "../../helpers/backend_helper";

function* getProduct({ payload: data }) {
  try {
    const response = yield call(get_products, data);
    if(response.status === 200){
      yield put(productApiResponseSuccess(GET_PRODUCTS, response.data));
    }else{
      yield put(productApiResponseError(GET_PRODUCTS, response));
    }
  } catch (error) {
    yield put(productApiResponseError(GET_PRODUCTS, error));
  }
}


export function* watchGetProducts() {
  yield takeEvery(GET_PRODUCTS, getProduct);
}



function* productSaga() {
  yield all([
    fork(watchGetProducts),
  ]);
}



export default productSaga;