import { all, fork } from "redux-saga/effects";

import productSaga from "./Products/saga";
import productListingSaga from "./ProductListing/saga";
import productDetailsSaga from "./ProductDetails/saga";
import searchSaga from "./SearchProduct/saga";
import userSaga from "./User/saga";
import cartSaga from "./Cart/saga";
import OrderProductSaga from "./OrderProduct/saga";

export default function* rootSaga() {
  yield all([
    fork(productSaga),
    fork(productListingSaga),
    fork(productDetailsSaga),
    fork(searchSaga),
    fork(userSaga),
    fork(cartSaga),
    fork(OrderProductSaga),
  ]);
}
