import { GET_RECENT_SEARCHES, GET_SEARCHES_ERROR, GET_SEARCHES_SUCCESS, GET_SEARCH_PRODUCT } from "./actionType";

// common success
export const searchApiResponseSeccess = (actionType, data) => ({
  type: GET_SEARCHES_SUCCESS,
  payload: { actionType, data },
});
// common error
export const searchApiResponseError = (actionType, error) => ({
  type: GET_SEARCHES_ERROR,
  payload: { actionType, error },
});

export const getRecentSearch = data => ({
  type: GET_RECENT_SEARCHES,
  payload: data,
});

export const getProductSearch = data => ({
  type: GET_SEARCH_PRODUCT,
  payload: data,
});