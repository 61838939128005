import { ADD_TO_CART, CART_API_RESPONSE_FAIL, CART_API_RESPONSE_SUCCESS, GET_CART, REMOVE_ITEM, UPDATE_QUANTITY } from "./actionType";

// common success
export const cartApiResponseSuccess = (actionType, data) => ({
  type: CART_API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const cartApiResponseError = (actionType, error) => ({
  type: CART_API_RESPONSE_FAIL,
  payload: { actionType, error },
});

export const getCart = data => ({
  type: GET_CART,
  payload: data,
});

export const addToCart = data => ({
    type: ADD_TO_CART,
    payload: data,
});

export const updateQuantity = data => ({
    type: UPDATE_QUANTITY,
    payload: data,
});

export const removeItem = data => ({
    type: REMOVE_ITEM,
    payload: data,
});
    