import { GET_ORDER_FAIL, GET_ORDER_SUCCESS , GET_ORDER} from "./actionType";

const INIT_STATE = {
    orderProducts: [],
    error: {},
    loading: false,
    errorStatus: false
};

const OrderProduct = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ORDER_SUCCESS:
            switch (action.payload.actionType) {
                case GET_ORDER:
                    return {
                        ...state,
                        orderProducts: action.payload.data.orders,
                        loading: false,
                        errorStatus: false
                    }
                default:
                    return { ...state };
            }
        case GET_ORDER_FAIL:
            switch (action.payload.actionType) {
                case GET_ORDER:
                    return {
                        ...state,
                        error: action.payload.error,
                        errorStatus: true,
                        loading: false
                    }
                default:
                    return { ...state };
            }

        default:
            return { ...state };
    }
}

export default OrderProduct;