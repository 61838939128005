import { API_PRODUCT_LISTING_ERROR, API_PRODUCT_LISTING_SUCCESS, GET_PRODUCTLISTING, GET_PRODUCTLISTING_BRAND, GET_PRODUCTLISTING_CATEGORY, GET_PRODUCTLISTING_FILTER, GET_PRODUCTLISTING_PARTS , SET_PRODUCT_FILTER} from './actionType.js';

// common success
export const productListingApiResponseSuccess = (actionType, data) => ({
  type: API_PRODUCT_LISTING_SUCCESS,
  payload: { actionType, data },
});
// common error
export const productListingApiResponseError = (actionType, error) => ({
  type: API_PRODUCT_LISTING_ERROR,
  payload: { actionType, error },
});

export const getProductListing = data => ({
  type: GET_PRODUCTLISTING,
  payload: data,
});

export const getProductListingCategory = data => ({
  type: GET_PRODUCTLISTING_CATEGORY,
  payload: data,
});

export const getProductListingBrand = data => ({
  type: GET_PRODUCTLISTING_BRAND,
  payload: data,
});

export const getProductListingPart = data => {
  console.log("part product filter:" , data)
  return{
    type: GET_PRODUCTLISTING_PARTS,
  payload: data,
  }
};

export const getProductListingFilter = data => ({
  type: GET_PRODUCTLISTING_FILTER,
  payload: data,
});

export const setProductFilter = data => ({
  type: SET_PRODUCT_FILTER,
  payload: data,
});