import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { productDetailsApiResponseError, productDetailsApiResponseSuccess } from "./action";
import { GET_PRODUCT_DETAILS } from "./actionType";
import { get_product_details } from "../../helpers/backend_helper";


function* getProductDetails({ payload: data }) {
  try {
    const response = yield call(get_product_details, data);
    console.log(response);
    if(response.status === 200){
      yield put(productDetailsApiResponseSuccess(GET_PRODUCT_DETAILS, response.data));
    }else{
      yield put(productDetailsApiResponseError(GET_PRODUCT_DETAILS, response));
    }
  } catch (error) {
    yield put(productDetailsApiResponseError(GET_PRODUCT_DETAILS, error));
  }
}

export function* watchGetProductDetails() {
  yield takeEvery(GET_PRODUCT_DETAILS, getProductDetails);
}

function* productDetailsSaga() {
  yield all([
    fork(watchGetProductDetails),
  ]);
}

export default productDetailsSaga;