import { API_RESPONSE_SUCCESS, API_RESPONSE_ERROR, GET_PRODUCTS } from "./actionType";

// common success
export const productApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const productApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getProducts = data => ({
  type: GET_PRODUCTS,
  payload: data,
});

