// import { act } from "react-dom/test-utils";
import { API_PRODUCT_LISTING_ERROR, API_PRODUCT_LISTING_SUCCESS, GET_PRODUCTLISTING, GET_PRODUCTLISTING_BRAND, GET_PRODUCTLISTING_CATEGORY, GET_PRODUCTLISTING_FILTER, GET_PRODUCTLISTING_PARTS, SET_PRODUCT_FILTER } from './actionType.js';

const INIT_STATE = {
    productListings: [],
    categories: [],
    brands: [],
    parts: [],
    totalProducts: 0,
    filter: {
        category: [],
        sorting: "price_low_to_high",
        parts: [],
        brand: [],
        subcategory: [],
        tags: "",
        page: 1,
        limit: 12,
        search: "",
        userId: "",
    },
    error: {},
    loading: false,
    errorStatus: false
};

const ProductListing = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_PRODUCT_LISTING_SUCCESS:
            switch (action.payload.actionType) {
                case GET_PRODUCTLISTING:
                    return {
                        ...state,
                        productListings: action.payload.data.data,
                        loading: false,
                        errorStatus: false
                    }

                case GET_PRODUCTLISTING_CATEGORY:
                    return {
                        ...state,
                        categories: action.payload.data.data,
                        loading: false,
                        errorStatus: false
                    }

                case GET_PRODUCTLISTING_BRAND:
                    return {
                        ...state,
                        brands: action.payload.data.data,
                        loading: false,
                        errorStatus: false
                    }

                case GET_PRODUCTLISTING_PARTS:
                    return {
                        ...state,
                        parts: action.payload.data.data,
                        loading: false,
                        errorStatus: false
                    }

                case GET_PRODUCTLISTING_FILTER:
                    return {
                        ...state,
                        totalProducts: action.payload.data.totalProducts ? parseInt(action.payload.data.totalProducts) : 0,
                        productListings: action.payload.data.data,
                        loading: false,
                        errorStatus: false
                    }

                default:
                    return { ...state };
            }

        case API_PRODUCT_LISTING_ERROR:
            switch (action.payload.actionType) {
                case GET_PRODUCTLISTING:
                    return {
                        ...state,
                        error: action.payload.data.message,
                        errorStatus: true,
                        loading: false
                    }

                case GET_PRODUCTLISTING_CATEGORY:
                    return {
                        ...state,
                        error: action.payload.data.message,
                        loading: false,
                        errorStatus: false
                    }

                case GET_PRODUCTLISTING_BRAND:
                    return {
                        ...state,
                        error: action.payload.data.message,
                        loading: false,
                        errorStatus: false
                    }

                case GET_PRODUCTLISTING_PARTS:
                    return {
                        ...state,
                        error: action.payload.data.message,
                        loading: false,
                        errorStatus: false
                    }

                case GET_PRODUCTLISTING_FILTER:
                    return {
                        ...state,
                        error: action.payload.data.message,
                        loading: false,
                        errorStatus: false
                    }

                default:
                    return { ...state };
            }

        case GET_PRODUCTLISTING_FILTER:
            return {
                ...state,
                filter: action.payload,
                loading: true,
            }
            
        case SET_PRODUCT_FILTER:
            return {
                ...state,
                filter: action.payload,
            }

        default:
            return { ...state };
    }
}

export default ProductListing;