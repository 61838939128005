import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { cartApiResponseSuccess, cartApiResponseError } from "./action";
import { get_cart, update_quantity, add_to_cart, remove_item } from "../../helpers/backend_helper";
import { ADD_TO_CART, GET_CART, REMOVE_ITEM, UPDATE_QUANTITY } from "./actionType";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';

function* getCart({ payload: data }) {
  try {
    const response = yield call(get_cart, data);
    if (response.status === 200) {
        yield put(cartApiResponseSuccess(GET_CART, response.data));
    } else {
        yield put(cartApiResponseError(GET_CART, response.data));
    }
  } catch (error) {
    yield put(cartApiResponseError(GET_CART, error));
  }
}

function* addToCart({ payload: data }) {
    try {
        const response = yield call(add_to_cart, data);
        if (response.status === 200) {
            // Swal.fire('Added', response.data.message, 'success');
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            yield put(cartApiResponseSuccess(ADD_TO_CART, response.data));
        } else {
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            yield put(cartApiResponseError(ADD_TO_CART, response.data));
        }
    } catch (error) {
        toast.error(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        yield put(cartApiResponseError(ADD_TO_CART, error));
    }
}

function* updateQuantity({ payload: data }) {
    try {
        const response = yield call(update_quantity, data);
        if (response.status === 200) {
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            
            yield put(cartApiResponseSuccess(UPDATE_QUANTITY, response.data));
        } else {
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            yield put(cartApiResponseError(UPDATE_QUANTITY, response.data));
        }
    } catch (error) {
        toast.error(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        yield put(cartApiResponseError(UPDATE_QUANTITY, error));
    }
}

function* removeItem({ payload: data }) {
    try {
        const response = yield call(remove_item, data);
        if (response.status === 200) {
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            yield put(cartApiResponseSuccess(REMOVE_ITEM, response.data));
        } else {
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            yield put(cartApiResponseError(REMOVE_ITEM, response.data));
        }
    } catch (error) {
        toast.error(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        yield put(cartApiResponseError(REMOVE_ITEM, error));
    }
}

export function* watchGetCart() {
  yield takeEvery(GET_CART, getCart);
}

export function* watchAddToCart() {
    yield takeEvery(ADD_TO_CART, addToCart);
}

export function* watchUpdateQuantity() {
    yield takeEvery(UPDATE_QUANTITY, updateQuantity);
}

export function* watchRemoveItem() {
    yield takeEvery(REMOVE_ITEM, removeItem);
}

export default function* cartSaga() {
  yield all([
    fork(watchGetCart),
    fork(watchAddToCart),
    fork(watchUpdateQuantity),
    fork(watchRemoveItem)
  ]);
}