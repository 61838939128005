import { combineReducers } from "redux";

import Product from "./Products/reducer";
import ProductListing from "./ProductListing/reducer";
import ProductDetails from "./ProductDetails/reducer";
import Search from "./SearchProduct/reducer";
import User from "./User/reducer";
import Cart from "./Cart/reducer";
import OrderProduct from "./OrderProduct/reducer";

const rootReducer = combineReducers({
    Product,
    ProductListing,
    ProductDetails,
    Search,
    User,
    Cart,
    OrderProduct
});

export default rootReducer;