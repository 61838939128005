import { API_PRODUCT_DETAILS_ERROR, API_PRODUCT_DETAILS_SUCCESS, GET_PRODUCT_DETAILS } from './actionType.js';

// common success
export const productDetailsApiResponseSuccess = (actionType, data) => ({
  type: API_PRODUCT_DETAILS_SUCCESS,
  payload: { actionType, data },
});
// common error
export const productDetailsApiResponseError = (actionType, error) => ({
  type: API_PRODUCT_DETAILS_ERROR,
  payload: { actionType, error },
});

export const getProductdetails = data => ({
  type: GET_PRODUCT_DETAILS,
  payload: data,
});