import {GET_ORDER, GET_ORDER_FAIL, GET_ORDER_SUCCESS} from './actionType';

export const getOrderApiResponseSuccess = (actionType, data) =>({
        type: GET_ORDER_SUCCESS,
        payload: { actionType, data },
    });
  
  export const getOrderApiResponseError = (actionType, error) => ({
    type: GET_ORDER_FAIL,
    payload: { actionType, error },
  });
  
  export const getOrder = data => ({
    type: GET_ORDER,
    payload: data,
   })

   