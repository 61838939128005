import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { productListingApiResponseError, productListingApiResponseSuccess } from "./action";
import { GET_PRODUCTLISTING, GET_PRODUCTLISTING_BRAND, GET_PRODUCTLISTING_CATEGORY, GET_PRODUCTLISTING_PARTS, GET_PRODUCTLISTING_FILTER } from "./actionType";
import { get_product_list, get_product_list_brand, get_product_list_category, get_product_list_filter, get_product_list_parts } from "../../helpers/backend_helper";


function* getProductListing({ payload: data }) {
  try {
    const response = yield call(get_product_list, data);
    if(response.status === 200){
      yield put(productListingApiResponseSuccess(GET_PRODUCTLISTING, response.data));
    }else{
      yield put(productListingApiResponseError(GET_PRODUCTLISTING, response.data));
    }
  } catch (error) {
    yield put(productListingApiResponseError(GET_PRODUCTLISTING, error));
  }
}

function* getProductListingCategory({ payload: data }) {
  try {
    const response = yield call(get_product_list_category, data);
    if(response.status === 200){
      yield put(productListingApiResponseSuccess(GET_PRODUCTLISTING_CATEGORY, response.data));
    }else{
      yield put(productListingApiResponseError(GET_PRODUCTLISTING_CATEGORY, response.data));
    }
  } catch (error) {
    yield put(productListingApiResponseError(GET_PRODUCTLISTING_CATEGORY, error));
  }
}

function* getProductListingBrand({ payload: data }) {
  try {
    const response = yield call(get_product_list_brand, data);
    if(response.status === 200){
      yield put(productListingApiResponseSuccess(GET_PRODUCTLISTING_BRAND, response.data));
    }else{
      yield put(productListingApiResponseError(GET_PRODUCTLISTING_BRAND, response.data));
    }
  } catch (error) {
    yield put(productListingApiResponseError(GET_PRODUCTLISTING_BRAND, error));
  }
}

function* getProductListingParts({ payload: data }) {
  try {
    const response = yield call(get_product_list_parts, data);
    if(response.status === 200){
      yield put(productListingApiResponseSuccess(GET_PRODUCTLISTING_PARTS, response.data));
    }else{
      yield put(productListingApiResponseError(GET_PRODUCTLISTING_PARTS, response.data));
    }
  } catch (error) {
    yield put(productListingApiResponseError(GET_PRODUCTLISTING_PARTS, error));
  }
}

function* getProductListingFilter({ payload: data }) {
  try {
    const response = yield call(get_product_list_filter, data);
    if(response.status === 200){
      yield put(productListingApiResponseSuccess(GET_PRODUCTLISTING_FILTER, response.data));
    }else{
      yield put(productListingApiResponseError(GET_PRODUCTLISTING_FILTER, response.data));
    }
  } catch (error) {
    yield put(productListingApiResponseError(GET_PRODUCTLISTING_FILTER, error));
  }
}

export function* watchGetProductListing() {
  yield takeEvery(GET_PRODUCTLISTING, getProductListing);
}

export function* watchGetProductListingCategory() {
  yield takeEvery(GET_PRODUCTLISTING_CATEGORY, getProductListingCategory);
}

export function* watchGetProductListingBrand() {
  yield takeEvery(GET_PRODUCTLISTING_BRAND, getProductListingBrand);
}

export function* watchGetProductListingParts() {
  yield takeEvery(GET_PRODUCTLISTING_PARTS, getProductListingParts);
}

export function* watchGetProductListingFilter() {
  yield takeEvery(GET_PRODUCTLISTING_FILTER, getProductListingFilter);
}

function* productListingSaga() {
  yield all([
    fork(watchGetProductListing),
    fork(watchGetProductListingCategory),
    fork(watchGetProductListingBrand),
    fork(watchGetProductListingParts),
    fork(watchGetProductListingFilter)
  ]);
}

export default productListingSaga;