import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import tokenExtraction from './decode';
import { useDispatch } from "react-redux";
import { setAuthorization } from './helpers/api_helper';

const AuthenticatedRoute = ({ children }) => {
    const dispatch = useDispatch();
    const authUser = sessionStorage.getItem("authUser") ? sessionStorage.getItem("authUser") : null;
    console.log("authUser", authUser);
    if(!authUser) {
        return <Navigate to="/" />;
    }
    let decodedToken = tokenExtraction(authUser, dispatch);
    if (decodedToken) {
        setAuthorization(authUser);
        return children;
    } else {
        return <Navigate to="/" />;
    }
};

export default AuthenticatedRoute;