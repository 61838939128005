import { AUTHENTICATION_ERROR, AUTHENTICATION_SUCCESS, DELETE_WISHLIST, GET_USER, GET_WISHLIST, LOGIN_USER, POST_WISHLIST, REGISTER_USER, GET_ADDRESS, NEW_ADDRESS, UPDATE_ADDRESS, DELETE_ADDRESS, LOGOUT_USER} from "./actionType";

// common success
export const authenticationResponseSuccess = (actionType, data) => ({
  type: AUTHENTICATION_SUCCESS,
  payload: { actionType, data },
});

// common error
export const authenticationResponseError = (actionType, error) => ({
  type: AUTHENTICATION_ERROR,
  payload: { actionType, error },
});

export const registerUser = (data) => ({
  type: REGISTER_USER,
  payload: data,
});

export const loginUser = (data) => ({
  type: LOGIN_USER,
  payload: data,
});

export const getUser = (data) => ({
  type: GET_USER,
  payload: data,
});

export const postWishlist = (data) => ({
  type: POST_WISHLIST,
  payload: data,
});

export const getWishlist = (data) => ({
  type: GET_WISHLIST,
  payload: data,
}); 

export const deleteWishlist = (data) => ({
  type: DELETE_WISHLIST,
  payload: data,
});

export const getAddress = (data) => ({
  type: GET_ADDRESS,
  payload: data,
});

export const newAddress = (data) => ({
  type: NEW_ADDRESS,
  payload: data,
});

export const updateAddress = (data) => ({
  type: UPDATE_ADDRESS,
  payload: data,
});

export const deleteAddress = (data) => ({
  type: DELETE_ADDRESS,
  payload: data,
});


export const logoutUser = () => ({
  type: LOGOUT_USER,
});

