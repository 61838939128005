import { AUTHENTICATION_ERROR, AUTHENTICATION_SUCCESS, GET_USER, LOGIN_USER, REGISTER_USER, POST_WISHLIST, GET_WISHLIST, DELETE_WISHLIST, GET_ADDRESS, NEW_ADDRESS, UPDATE_ADDRESS, DELETE_ADDRESS, LOGOUT_USER} from "./actionType";

const INIT_STATE = {
    user: sessionStorage.getItem("authUser") ? sessionStorage.getItem("authUser") : null,
    userData: {},
    userAddress: [],
    error: "",
    wishlistError: "",  
    message: "",
    wishlist: [],
    loading: false,
    errorStatus: false
};

const User = (state = INIT_STATE, action) => {
    switch (action.type) {
        case AUTHENTICATION_SUCCESS:
            switch (action.payload.actionType) {
                case REGISTER_USER:
                    return {
                        ...state,
                        message: action.payload.data.message,
                        loading: false,
                        errorStatus: false
                    }
                
                case LOGIN_USER:
                    return {
                        ...state,
                        user: action.payload.data.token,
                        message: action.payload.data.message,
                        loading: false,
                        errorStatus: false
                    }
                
                case GET_USER:
                    return {
                        ...state,
                        userData: action.payload.data.user,
                        userAddress: action.payload.data.user.address,
                        loading: false,
                        errorStatus: false
                    }
                
                case GET_WISHLIST:
                    return {
                        ...state,
                        wishlist: action.payload.data.data.products,
                    }
                
                case POST_WISHLIST:
                    return {
                        ...state,
                        wishlist: action.payload.data.data.products,
                    }
                
                case DELETE_WISHLIST:
                    return {
                        ...state,
                        wishlist: action.payload.data.data.products,
                    }
                
                case GET_ADDRESS:
                    return {
                        ...state,
                        userAddress: action.payload.data.address,
                    }
                
                case NEW_ADDRESS:
                    return {
                        ...state,
                        userAddress: action.payload.data.data.address,
                    }
                case UPDATE_ADDRESS:
                    return {
                        ...state,
                        userAddress: action.payload.data.data.address,
                    }
                case DELETE_ADDRESS:
                    return {
                        ...state,
                        userAddress: action.payload.data.data.address,
                    }
                default:
                    return { ...state };
            }

        case AUTHENTICATION_ERROR:
            switch (action.payload.actionType) {
                case REGISTER_USER:
                    return {
                        ...state,
                        errorStatus: true,
                        loading: false,
                        error: action.payload.data ? action.payload.data.message : action.payload.error,
                    }
                
                case LOGIN_USER:
                    return {
                        ...state,
                        loading: false,
                        errorStatus: false,
                        error: action.payload.data ? action.payload.data.message : action.payload.error,
                    }
                
                case GET_USER:
                    return {
                        ...state,
                        loading: false,
                        errorStatus: false,
                        error: action.payload.data ? action.payload.data.message : action.payload.error,
                    }
                
                case GET_WISHLIST:
                    return {
                        ...state,
                        wishlistError: action.payload.data ? action.payload.data.message : action.payload.error,
                    }
                
                case POST_WISHLIST:
                    return {
                        ...state,
                        wishlistError: action.payload.data ? action.payload.data.message : action.payload.error,
                    }
                
                case DELETE_WISHLIST:
                    return {
                        ...state,
                        wishlistError: action.payload.data ? action.payload.data.message : action.payload.error,
                    }
                
                case GET_ADDRESS:
                    return {
                        ...state,
                        error: action.payload.data ? action.payload.data.message : action.payload.error,
                    }
                
                case NEW_ADDRESS:
                    return {
                        ...state,
                        error: action.payload.data ? action.payload.data.message : action.payload.error,
                    }
                
                case UPDATE_ADDRESS:
                    return {
                        ...state,
                        error: action.payload.data ? action.payload.data.message : action.payload.error,
                    }                
                case DELETE_ADDRESS:
                    return {
                        ...state,
                        error: action.payload.data ? action.payload.data.message : action.payload.error,
                    }
                default:
                    return { ...state };
            }
        
        case REGISTER_USER:
            return {
                ...state,
                loading: true
            }
        
        case LOGOUT_USER:
            localStorage.removeItem("authUser");
            sessionStorage.removeItem("authUser");
            return {
                ...state,
                user: null,
                userData: {},
                userAddress: [],
                error: "",
                wishlistError: "",  
                message: "",
                wishlist: [],
                loading: false,
                errorStatus: false
            }

        default:
            return { ...state };
    }
}

export default User;